import React, { useContext } from "react";
import BaseLayout from "../components/global/BaseLayout";
import { GlobalDataContext } from "../context/context";
import TransparentHeader from "../components/global/TransparentHeader";



export default function Products() {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <BaseLayout PageName="Products">
            <TransparentHeader
                headertitle={'Products'}
                Subheader={'Products'}
                bgimg={`${rpdata?.stock?.[5]}`}
            />

            <section className="h-screen w-full text-center flex justify-center items-center">
                <h1>Our Products, Coming Soon!!</h1>

            </section>

        </BaseLayout>
    );
}