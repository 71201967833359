import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { FiPhoneCall } from "react-icons/fi";
function HeroVideo({ urlVideo, image }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center ">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-center text-white">
                    <div className="w-full text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="px-5 md:px-[20%]">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
            </div>

            <div className="w-full h-auto flex justify-center">
                <div className=" flex md:flex-row flex-col md:w-[70%] w-[100%] bg2 text-white md:-mt-20 -mb-12 -mt-5 relative">
                    <div className="  w-full md:w-3/5 bg-cover bg-center h-[240px]" style={{ backgroundImage: `url("${image}")` }}>
                    </div>
                    <div className="w-full md:w-2/5 h-full flex flex-col items-center justify-center">
                        <FiPhoneCall className="md:text-[35px] text-[20px]" />
                        <h2 className="md:text-[16px] text-[20px] font-bold mt-3 text-white ">Call us</h2>
                        {rpdata?.dbPrincipal?.phones.slice(0, 3)?.map((item, index) => {
                            return (
                                <ul key={index}>
                                    <li className="md:py-1 py-4 flex">
                                        <a
                                            href={`tel:+1${item.phone}`}
                                            className="md:mx-6 mx-2 items-center flex"
                                        >
                                            <span className="flex flex-col md:pt-[5px]">
                                                <span className="md:pl-3 font-bold md:text-[22px] text-[14px]">
                                                    {item.phone}
                                                </span>
                                                {rpdata?.dbPrincipal?.phones?.[0].name.length > 0 ? (
                                                    <span className="md:pl-3 mt-[-5px]">{item.name}</span>
                                                ) :
                                                    null
                                                }

                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;